import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

/* Import Contexts */
import { useOrderContext } from '../contexts/OrderContext.js';

/* Import Components */
import Input from '../components/Input.js'
import Button from '../components/Button.js';

const Order = () => {

    const { 
        orderId,
        name,
        setName,
        deliveryAddress,
        setDeliveryAddress,
        boxQuantity,
        setBoxQuantity,
        contactEmail,
        setContactEmail,
        contactPhone,
        setContactPhone
    } = useOrderContext();

    const [page, setPage] = useState(window.location.pathname);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error1, setError1] = useState(null);
    const [readyToConfirm, setReadyToConfirm] = useState(false);
    const handleReviewOrder = () => {
        setLoading(true);

        setTimeout(() => {
            if (name && deliveryAddress && boxQuantity && contactEmail && contactPhone) {
                navigate(`/order/${orderId}/confirm`);
                setLoading(false);
            } else {
                setError1("Please fill out all fields to continue.");
                setLoading(false);
            }
        }, 500);

        setTimeout(() => {
            setLoading(false);
        }, 10000);
    };

    return (
        <div class="flex flex-1 flex-col h-full w-full items-center justify-center space-y-4 animate-fade animate-duration-700">
            <div class="flex max-w-xl flex-col items-start justify-center relative space-y-4">
                <a class="font-new-kansas-medium sm:text-7xl text-5xl text-forbidden-green dark:text-forbidden-cream">let's get you some applesauce.</a>
                <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream whitespace-wrap">Get Forbidden delivered to your doorstep by filling out the form below.</p>
            </div>

            <div class="flex w-full max-w-xl flex-col items-center justify-center space-y-2">
                <Input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                />
                <Input
                    type="text"
                    value={deliveryAddress}
                    onChange={(e) => setDeliveryAddress(e.target.value)}
                    placeholder="Delivery address"
                />
                <div class="flex flex-col w-full items-start justify-center">
                    <p class="font-new-spirit-light-condensed text-xs text-forbidden-grey dark:text-forbidden-cream whitespace-nowrap">Let us know how many boxes we should send. Each box contains 12 jars.</p>
                    <div class="flex w-full flex-row items-center justify-center space-x-2">
                        <Input
                            type="number"
                            value={boxQuantity}
                            onChange={(e) => setBoxQuantity(e.target.value)}
                            placeholder="Order quantity"
                        />
                    </div>
                </div>
                <div class="flex flex-col w-full items-start justify-center">
                    <p class="font-new-spirit-light-condensed text-xs text-forbidden-grey dark:text-forbidden-cream whitespace-nowrap">We'll use this contact info to deliver updates on your order.</p>
                    <div class="flex w-full flex-row items-center justify-center space-x-2">
                        <Input
                            type="email"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                            placeholder="Contact email"
                        />
                        <Input
                            type="phone"
                            value={contactPhone}
                            onChange={(e) => setContactPhone(e.target.value)}
                            placeholder="Contact phone"
                        />
                    </div>
                </div>

                <div class="flex items-center w-full pt-4">
                    <div class="w-full h-full">
                        <Button 
                                title="Next"
                                theme="dark"
                                onClick={handleReviewOrder}
                                loading={loading}
                                error={error1}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Order;