import { createContext, useContext, useState, useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';

export const LearnContext = createContext({});

export const useLearnContext = () => useContext(LearnContext);

export const LearnContextProvider = ({ children }) => {

    const [submissionId, setSubmissionId] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const generateSubmissionId = () => {
            setSubmissionId(uuidv4());
        };

        generateSubmissionId();

    }, []);

    const value = {
        submissionId,
        setSubmissionId,
        name,
        setName,
        email,
        setEmail
    };

    return (
        <LearnContext.Provider value={value}>
            {children}
        </LearnContext.Provider>
    );
};