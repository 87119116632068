import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

/* Import Contexts */
import { useOrderContext } from '../contexts/OrderContext.js';

/* Import Components */
import Button from '../components/Button.js';

const Confirmation = () => {

    const { 
        orderId,
        name,
        setName,
        deliveryAddress,
        setDeliveryAddress,
        boxQuantity,
        setBoxQuantity,
        contactEmail,
        setContactEmail,
        contactPhone,
        setContactPhone
    } = useOrderContext();

    const [page, setPage] = useState(window.location.pathname);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [error2, setError2] = useState(null);
    const handleSubmit = () => {
        setLoading(true);

        setTimeout(() => {
                const emailParams = {
                    order_id: orderId,
                    store_name: name,
                    store_address: deliveryAddress,
                    case_quantity: boxQuantity,
                    delivery_date: 'N/A',
                    contact_email: contactEmail,
                    contact_phone: contactPhone
                };
            
                emailjs.init("JsWXMKoEmYGkVNzFs");
                
                emailjs.send('service_yqwypgr', 'template_p39ee58', emailParams)
                    .then(function(response) {
                        navigate(`/order/${orderId}/success`);
                    }, function(error) {
                        setError2("Something went wrong. Please try again.")
                    }
                );

                setLoading(false);
        }, 500);
    };

    return (
        <div class="flex flex-1 flex-col h-screen w-full max-w-xl mx-auto items-center justify-center p-4 space-y-6 animate-fade-left animate-duration-700">
            <div class="flex flex-col w-full space-y-2">
                <p class="font-new-kansas-medium text-7xl text-forbidden-green dark:text-forbidden-cream">confirm order details.</p>
                <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream">Please confirm your order below. We'll reach out by email or phone after we receive your order to confirm its details and set up a delivery appointment.</p>
            </div>

            <div class="flex flex-col w-full border-t-[0.5px] border-financr-slate" />

            <div class="flex flex-col w-full space-y-2">
                <div class="flex flex-row w-full items-start justify-between">
                    <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream">Name</p>
                    <p class="font-new-spirit-light-condensed text-sm text-forbidden-grey dark:text-forbidden-cream">{name}</p>
                </div>
                <div class="flex flex-row w-full items-start justify-between">
                    <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream">Delivery Address</p>
                    <p class="font-new-spirit-light-condensed text-sm text-forbidden-grey dark:text-forbidden-cream">{deliveryAddress}</p>
                </div>
                
                <div class="flex flex-row w-full items-start justify-between">
                    <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream">Number of Boxes (12 jars / box)</p>
                    <p class="font-new-spirit-light-condensed text-sm text-forbidden-grey dark:text-forbidden-cream">{boxQuantity}</p>
                </div>
                
            </div>

            <div class="flex flex-col w-full border-t-[0.5px] border-financr-slate" />

            <div class="flex flex-col w-full space-y-2">
                <div class="flex flex-row w-full items-start justify-between">
                    <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream">Email</p>
                    <p class="font-new-spirit-light-condensed text-sm text-forbidden-grey dark:text-forbidden-cream">{contactEmail}</p>
                </div>
                <div class="flex flex-row w-full items-start justify-between">
                    <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream">Phone</p>
                    <p class="font-new-spirit-light-condensed text-sm text-forbidden-grey dark:text-forbidden-cream">{contactPhone}</p>
                </div>
            </div>

            <div class="flex flex-col-reverse sm:flex-row w-full space-x-0 sm:space-x-2">
                <div class="flex w-full items-center justify-center pt-1 sm:pt-0">
                    <Button
                        title="Back"
                        onClick={() => navigate(-1)}
                        theme="light"
                    />
                </div>
                
                <div class="flex w-full items-center justify-center pb-1 sm:pb-0">
                    <Button
                        title="Place order"
                        onClick={handleSubmit}
                        theme="dark"
                        loading={loading}
                        error={error2}
                    />
                </div>
            </div>
            
        </div>
    );
};

export default Confirmation;