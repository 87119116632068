import { createContext, useContext, useState, useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';

export const OrderContext = createContext({});

export const useOrderContext = () => useContext(OrderContext);

export const OrderContextProvider = ({ children }) => {

    const [orderId, setOrderId] = useState();
    const [name, setName] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [boxQuantity, setBoxQuantity] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');

    useEffect(() => {
        const generateOrderId = () => {
            setOrderId(uuidv4());
        };

        generateOrderId();

    }, []);

    const value = {
        orderId,
        setOrderId,
        name,
        setName,
        deliveryAddress,
        setDeliveryAddress,
        boxQuantity,
        setBoxQuantity,
        deliveryDate,
        setDeliveryDate,
        contactEmail,
        setContactEmail,
        contactPhone,
        setContactPhone,
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
};