import React, { useState } from 'react';

/* Import Components */
import Button from '../components/Button.js';
import ForbiddenLogo from '../assets/forbidden_logo_tags.svg';

const Home = () => {

    const [page, setPage] = useState(window.location.pathname);

    return (
        <div class="flex flex-1 flex-col h-full w-full items-center justify-center space-y-4 animate-fade animate-duration-700">
            <div className="absolute mb-6 items-center justify-center z-0 animate-fade animate-delay-300">
                <img src={ForbiddenLogo} alt="Forbidden Logo" class="w-full h-full object-cover" />
            </div>
            
            <a class="font-new-spirit-medium-condensed text-5xl text-forbidden-green dark:text-forbidden-cream">
                Forbidden
                <p class="font-new-spirit-light-condensed text-xs uppercase text-forbidden-green dark:text-forbidden-cream absolute -top-4 -right-20 whitespace-nowrap">Sinfully Delicious Applesauce ™</p>
            </a>

            <div class={`flex flex-row items-center justify-between ${page == '/' && 'animate-fade-up animate-delay-300'}`}>
                <div></div>

                <div class="flex md:flex-row flex-col items-center justify-center md:space-x-2 space-x-0 md:space-y-0 space-y-2">
                    <Button 
                        title="Start a new order"
                        onClick={() => window.location.href = "/order"}
                    />
                </div>
            </div>

            <div class="absolute bottom-4 flex flex-row w-full items-center justify-center animate-fade animate-duration-700 animate-delay-300">
                <div class="flex flex-col items-center justify-center space-y-2">
                    <div class="flex flex-row w-full items-center justify-center space-x-2">
                        {/* <a href="/about" class="font-new-spirit-light-condensed text-sm text-forbidden-grey border-[0.25px] px-4 py-1.5 rounded-xl border-forbidden-grey hover:text-forbidden-green hover:border-forbidden-green transition-colors duration-400">About</a> */}
                        <a href="/careers" class="font-new-spirit-light-condensed text-sm text-forbidden-grey border-[0.25px] px-4 py-1.5 rounded-xl border-forbidden-grey hover:text-forbidden-green hover:border-forbidden-green transition-colors duration-400">Careers</a>
                        <a href="mailto:jaxfloyd@stanford.edu" class="font-new-spirit-light-condensed text-sm text-forbidden-grey border-[0.25px] px-4 py-1.5 rounded-xl border-forbidden-grey hover:text-forbidden-green hover:border-forbidden-green transition-colors duration-400">Contact</a>
                        <a href="/learn" class="font-new-spirit-light-condensed text-sm text-forbidden-grey border-[0.25px] px-4 py-1.5 rounded-xl border-forbidden-grey hover:text-forbidden-green hover:border-forbidden-green transition-colors duration-400">Learn</a>
                    </div>
                    {/* <div class="flex flex-1 w-full h-full">
                        <p class="flex w-full h-full flex-1 font-new-spirit-light-condensed text-xs text-forbidden-grey dark:text-forbidden-cream">[i] Please note that we're only fulfilling orders in the New York City metro area right now. Check back soon for expanded distribution.</p>
                    </div> */}
                </div>
                
            </div>

            

            
        </div>
    );
};

export default Home;