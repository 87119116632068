import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

/* Import Contexts */
import { useLearnContext } from '../contexts/LearnContext.js';

/* Import Components */
import Input from '../components/Input.js'
import Button from '../components/Button.js';

const Learn = () => {

    const { 
        submissionId,
        setSubmissionId,
        name,
        setName,
        email,
        setEmail
    } = useLearnContext();

    const [page, setPage] = useState(window.location.pathname);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error1, setError1] = useState(null);
    const [error2, setError2] = useState(null);
    const [readyToConfirm, setReadyToConfirm] = useState(false);
    const handleSubmitForm = () => {
        setLoading(true);

            setTimeout(() => {
                if (name && email) {
                    setTimeout(() => {
                        const emailParams = {
                            submission_id: submissionId,
                            name: name,
                            email: email
                        };
                    
                        emailjs.init("JsWXMKoEmYGkVNzFs");
                        
                        emailjs.send('service_yqwypgr', 'template_9d0mxno', emailParams)
                            .then(function(response) {
                                navigate(`/learn/${submissionId}/thanks`);
                            }, function(error) {
                                setError2("Something went wrong. Please try again.")
                            }
                        );
        
                        setLoading(false);
                }, 500);
                navigate(`/learn/${submissionId}/thanks`);
                setLoading(false);
            } else {
                setError1("Please fill out all fields to continue.");
                setLoading(false);
            }
        }, 500);

        setTimeout(() => {
            setLoading(false);
        }, 10000);
    };

    return (
        <div class="flex flex-1 flex-col h-full w-full items-center justify-center space-y-4 animate-fade animate-duration-700">
            <div class="flex max-w-xl flex-col items-start justify-center relative space-y-4">
                <a class="font-new-kansas-medium sm:text-7xl text-5xl text-forbidden-green dark:text-forbidden-cream">we're stoked you want to learn more about forbidden.</a>
                <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream whitespace-wrap">We're still building our website and socials. Leave your contact info so we can update you as we grow.</p>
            </div>

            <div class="flex w-full max-w-xl flex-col items-center justify-center space-y-2">
               
                <div class="flex flex-col w-full items-start justify-center">
                    <div class="flex w-full flex-row items-center justify-center space-x-2">
                        <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                        />
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                        />
                    </div>
                </div>

                <div class="flex items-center w-full pt-4">
                    <div class="w-full h-full">
                        <Button 
                                title="Sign up for updates"
                                theme="dark"
                                onClick={handleSubmitForm}
                                loading={loading}
                                error={error1}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Learn;